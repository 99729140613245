import styled from "styled-components";
import img from "../../assets/images/ligaHome.png"

export const ThemeContainer = styled.div`
background: ${({ theme }) => {
  if (theme.name === "green-theme") {
    return `url(${img})`;

  } else if (theme.name === "blue-theme") {
    return theme.colors.background;
  }
}};
background-repeat: no-repeat;
background-size: cover;
border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0px;
 
`;

export const ButtonColor = styled.button`
    background-color: ${({ theme }) => theme.colors.buttonBackground};
    float: left;
    height: 48px;
    margin-top: 23px;
    text-align: center;
    width: 184px;
    color: #fff;
    font-weight: 700;
    border: none;

    &:hover {
      background-color: ${({ theme }) => theme.colors.buttonBackgroundHover};
  }

    
`;

export const NewProjectButton=styled.button`
    background-color: ${({ theme }) => theme.colors.projectButtonColor};
    float: left;
    height: 48px;
    margin-top: 23px;
    text-align: center;
    width: 184px;
    color: #fff;
    font-weight: 700;
    border: none;

    &:hover {
      background-color: ${({ theme }) => theme.colors.buttonBackgroundHover};
  }

    
`;

export const ThemeButton = styled.button`
  margin: 0 5px;
  padding: 10px;
  font-size: 0.5rem;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: none;
`;

export const ProjectHeaderBar = styled.div`
  background-color: ${({ theme }) => theme.colors.projectHeadBar};
  height: 71px; // Adjust the height as needed
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  flex-wrap: wrap;
  
`;

export const QuestionCount = styled.span`
float: right;
color: ${({ theme }) => theme.colors.questionCountColor};
margin-top: 5px;
font-size: 12px;
  
`;

export const FooterColor = styled.p`
text-align: center;
background-color: ${({ theme }) => theme.colors.footerColor};
display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    border-top: 1px solid #e9ecef;
    font-family: "Open Sans";
font-size: 14px;
color: #888888;
width: auto;

`;

export const HomeBorderBox = styled.div`
background-color: ${({ theme }) => theme.colors.HomeBorderBoxColor};
width: 120%;
height: 90%;
padding: 20px;

`;

export const ProgramTableBar = styled.div`
  background-color: ${({ theme }) => theme.colors.programTableBar};

`;

export const SignupColor = styled.div`
  background-color: ${({ theme }) => theme.colors.signupColor};

`;
export const ProjectsideBar = styled.div`
  background-color: ${({ theme }) => theme.colors.projectSideBar};
  height: 50px; // Adjust the height as needed
  width: 100%;
`;



export const ProgramHead = styled.div`
background-color: ${({ theme }) => theme.colors.programHead}; 
`;

export const ProgramSidebar = styled.div`
background-color: ${({ theme }) => theme.colors.programSidebar}; 
`;



export const EligibleHead = styled.div`
background-color: ${({ theme }) => theme.colors.eligibleHead};
width: 267%;
height: 40px;
border: transparent;
`;


export const CardHome = styled.div`
background-color: ${({ theme }) => theme.colors.quoteBgc};
`;

export const CardHomeClr = styled.div`
background-color: ${({ theme }) => theme.colors.cardBorderClr};
border-top:1px solid hsl(0, 0%, 40%)

}
`;

export const RadioButtonColor = styled.input`
accent-color: ${({ theme }) => theme.colors.buttonBackgroundHover};

`;


export const CardHomeText = styled.div`
color: ${({ theme }) => theme.colors.quoteTitle};
font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 32px;
    text-align: left;
    padding: 1px;
    margin-left: 2px;
    margin-top: 0px;
`;



export const CardHomeTextParagraph = styled.div`
color: ${({ theme }) => theme.colors.text};
width: 309px;
height: 118px;
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
letter-spacing: normal;
line-height: 22px;
text-align: left;
padding: 0px;
margin-left: 2.5px;
margin-top: 13px;
`;

export const ButtonLogin = styled.button`
background-color:${({ theme }) => theme.colors.button};
    color: #fff;
    font-weight: 700;
    height: 48px;
    width: 162px;
margin-left: 115px;
border: none;

&:hover {
  background-color: ${({ theme }) => theme.colors.buttonBackgroundHover};
}
`;

export const ButtonContinue = styled.button`
background-color:${({ theme }) => theme.colors.button};
    color: #fff;
    font-weight: bold;
    height: 48px;
    width: 162px;
margin-left: 715px;
border: none;

&:hover {
  background-color: ${({ theme }) => theme.colors.buttonBackgroundHover};
}

`;

export const ButtonProject = styled.button`
background-color:${({ theme }) => theme.colors.button};
float: left;
height: 48px;
margin-top: 23px;
text-align: center;
width: 184px;
border: none;
color: #fff;
font-weight: 700;

`;

export const ButtonReadiness = styled.button`
background-color:${({ theme }) => theme.colors.button};
    color: #fff;
    font-weight: 700;
    height: 48px;
    width: 224px;
    margin-right: 0px;
    margin-left:190px;
    border: none;
    &:hover {
      background-color: ${({ theme }) => theme.colors.buttonBackgroundHover};
    }
`;

export const ButtonPrint = styled.div`
color:${({ theme }) => theme.colors.button}
height: 48px;
width: 128px;
margin-right: 25%;
padding: 8px;
font-weight: bold;
border: 3px solid ${({ theme }) => theme.colors.button};

&:hover {
  background-color: ${({ theme }) => theme.colors.buttonBackgroundHover};
  color: #fff;
  font-weight: bold;
  border: 3px solid ${({ theme }) => theme.colors.buttonBackgroundHover};
}
`;

export const PcaContinue = styled.button`
background-color:${({ theme }) => theme.colors.button};
    color: #fff;
    font-size: 20px;
    height: 48px;
    width: 155px;
    margin-left: 343px;
    border: none;
    color: #212529;
`;


export const ShowReadiness = styled.button`
background-color:${({ theme }) => theme.colors.button};
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    height: 48px;
    width: 352px;
    margin-left: 190px;
    border: none;

    &:hover {
      background-color: ${({ theme }) => theme.colors.buttonBackgroundHover};
      color: #fff;
    }

`;

export const BackButton = styled.a`
width: 48px;
  background-color: white;
  border-color: white;
  color: #aeb0b5;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.3px;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  padding: 0px 0px;
  margin-left: 41px;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.buttonBackgroundHover};
  }

`;

export const LinkColor = styled.a`
text-decoration: none !important;
color : ${({ theme }) => theme.colors.button};

&:hover {
  color: ${({ theme }) => theme.colors.buttonBackgroundHover};
}

  

`;

export const ContactUsButton = styled.button`
background-color:${({ theme }) => theme.colors.button};
color: #fff;
width: 113px;
height: 48px;
margin-top: 6%;
margin-left: 430px;
border: none;
font-weight: bold;

&:hover {
  background-color: ${({ theme }) => theme.colors.buttonBackgroundHover};
}
`

export const FeatureFundText = styled.div`
color: ${({ theme }) => theme.colors.quoteTitle};
font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 32px;
    text-align: left;
    padding: 1px;
    margin-left: 2px;
    margin-top: -14px;`


    export const FeatureFundTextTitle = styled.div`
    color: ${({ theme }) => theme.colors.quoteTitle};
font-family: "Open Sans";
    font-size: 36px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 32px;
    text-align: left;
    padding: 1px;
    margin-left: 45px;
    margin-top: 0px;`
    

    export const FeaturFundLearn = styled.div`
    color:${({ theme }) => theme.colors.button};
    height: 48px;
    width: auto;
    padding: 8px;
    font-weight: bold;
    border: 3px solid ${({ theme }) => theme.colors.button};

    &:hover {
      background-color: ${({ theme }) => theme.colors.buttonBackgroundHover};
      color: #fff;
      font-weight: bold;
      border: 3px solid ${({ theme }) => theme.colors.buttonBackgroundHover};
  }
    `
export const ProgramsHead = styled.div`
color: gray;
`
export const ButtonSignup = styled.button`
background-color:${({ theme }) => theme.colors.button};
    color: #fff;
    font-weight: 700;
    height: 48px;
    width: 162px;
    border: none;

&:hover {
  background-color: ${({ theme }) => theme.colors.buttonBackgroundHover};
}
`;

export const ButtonLearnmore = styled.a`
    color:${({ theme }) => theme.colors.button};
    font-weight: bold;
    height: 48px;
    width: 183px;
    padding: 9px;
    justify-content: center;
    display: flex;
    text-decoration: blink;
    border: 3px solid ${({ theme }) => theme.colors.button};
    &:hover {
      background-color: ${({ theme }) => theme.colors.buttonBackgroundHover};
      color: #fff;
      border: 3px solid ${({ theme }) => theme.colors.buttonBackgroundHover};
  }
`;

export const ProjectsTilte = styled.div`
color: ${({ theme }) => theme.colors.ProjectsTilte};
font-family: "Open Sans";
font-size: 36px;
font-style: normal;
letter-spacing: normal;
line-height: 44px;
text-align: left;
`
export const ProjectSideBar =styled.div`
color: ${({ theme }) => theme.colors.ProjectSideBar};

`
export const DocumentLinkColor = styled.a`
color: ${({ theme }) => theme.colors.DocumentLinkColor};
font-size: 14px;
text-decoration: none !important;
&:hover {
  color: ${({ theme }) => theme.colors.buttonBackgroundHover};
}
`
export const LinkIcon = styled.span`
color: ${({ theme }) => theme.colors.LinkIcon};
size:inherit;
margin-right:5px;
font-size: 14px;
`

export const EligibilityLinkIcon = styled.span`
color: ${({ theme }) => theme.colors.LinkIcon};
size:inherit;

`

export const PopupContinue = styled.button`
background-color:${({ theme }) => theme.colors.button};
    color: #fff;
    font-size: 20px;
    height: 48px;
    width: 155px;
    margin-left: 343px;
    border: none;
    color: #212529;
`