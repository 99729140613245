import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { Col, Container, Form, OverlayTrigger, Popover, Row } from "react-bootstrap";
import SelectInput from "../../components/form-inputs/select-inputs";
import TitleCard from "../../components/title-card/titlecard";
import { BaseAPI } from "../../providers/base-api";
import { useNavigate } from "react-router-dom";
import { CODETABLE_TYPES } from "../../providers/models/codetable";
import { ProjectContext, ProjectContextType, useProject } from "./project";
import { Typeahead } from "react-bootstrap-typeahead";
import img4 from "../../assets/images/info-circle-blue.svg";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "./newprojects.css";
import { sortDataProgram } from "../../utils/common-utils";
import { ButtonContinue, ProjectsTilte } from "../../components/styles/ThemeSwitching.styled";
import { LinkColor } from "../../components/styles/ThemeSwitching.styled";
import { useAuth } from "../../hooks/useAuth";
import { customTranslate } from "../../utils/googleTranslate";
export interface CodeTableResult {
  project_location: any;
  implementing_entity: any;
  type_of_project: any;
  project_category: any;
  approximate_project_cost: any;
  project_activity: any;
  preparation_stage: any;
  keywords: any;
  project_funding_type: any;
}

export default function CreateProject() {
  const defaultCodeTables: { [key: string]: any[] } = useMemo(() => ({}), []);
  const [codeTableData, setcodeTableData] = useState(defaultCodeTables);
  const [validated, setValidated] = useState(false);
  const { project } = useProject();
  const [projectCategoryList, setProjectCategoryList] = useState([]);
  const [multiSelectKeyword, setMultiSelectKeyword] = useState([]);
  const projectContext: ProjectContextType = useContext(ProjectContext);
  const { user } = useAuth()

  const onSubmit = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);

      return;
    }
    setValidated(true);
    try {
      await projectContext.saveProject((savedProjectId) => {
        navigate(`/projects/${savedProjectId}/programs`);
      });
    } catch (error) {
    }
    setValidated(true);
  };

  const handleTypeOfProjectChange = async (value) => {
    projectContext.handleProjectChange("typeOfProjectId", value);
    projectContext.handleProjectChange("projectCategoryId", '');
  };

  const handleOnBlur = async (event: ChangeEvent<HTMLInputElement>) => {
    projectContext.handleProjectChange("query", event.target.value);
  }

  const handleMultiSelect = async (value, keyName, callback) => {
    let data = value.map(item => item.value)?.join(',');
    projectContext.handleProjectChange(keyName, data);
   
    if (keyName === 'keywordIds' && value.length > 0) {
      const selectedKeyword = value[0]; 
  
      const projectCategory = codeTableData[CODETABLE_TYPES.PROJECT_CATEGORY].find(
        (category) => category.value=== selectedKeyword.value
      );
      
      if (projectCategory) {
        projectContext.handleProjectChange("projectCategoryId", projectCategory.value);
      }
  
      const typeOfProject = codeTableData[CODETABLE_TYPES.PROJECT_TYPES].find(
        (projectType) => projectType.value === selectedKeyword.parentId
      );
  
      if (typeOfProject) {
        projectContext.handleProjectChange("typeOfProjectId", typeOfProject.value);
      }
    } else{
      projectContext.handleProjectChange("projectCategoryId", '');
      projectContext.handleProjectChange("typeOfProjectId", '');
     
    }
  
    if (typeof callback !== 'undefined') {
      callback(value);
    }
  };
 
  useEffect(() => {
    BaseAPI.getCodeTable([
      CODETABLE_TYPES.KEYWORD,
      CODETABLE_TYPES.PROJECT_LOCATION,
      CODETABLE_TYPES.IMPLEMENTING_ENTITY,
      CODETABLE_TYPES.PROJECT_TYPES,
      CODETABLE_TYPES.PROJECT_CATEGORY,
      CODETABLE_TYPES.PROJECT_COST,
      CODETABLE_TYPES.PROJECT_ACTIVITY,
      CODETABLE_TYPES.PROJECT_STAGE,
      CODETABLE_TYPES.FUNDING_TYPE,
      CODETABLE_TYPES.PROGRAM_STATUS
    ]).then((result: any) => {
      const keyword = sortDataProgram(result[CODETABLE_TYPES.PROJECT_LOCATION], 'text', 'asc');
      result = { ...result, result, [CODETABLE_TYPES.PROJECT_LOCATION]: keyword }
      const location = sortDataProgram(result[CODETABLE_TYPES.KEYWORD], 'text', 'asc');
      result = { ...result, result, [CODETABLE_TYPES.KEYWORD]: location }
      const textsToTranslate = Object.values(CODETABLE_TYPES).map((type) =>
        result[type]?.map(item => item.text)
      ).flat();
      const selectedLanguage = localStorage.getItem('selectedLanguage');
      customTranslate(textsToTranslate, 'auto', selectedLanguage)
        .then((translatedData) => {
          const translatedTexts = translatedData.map((translation) => translation[0]);

          let textIndex = 0;
          const translatedResult = { ...result };

          Object.values(CODETABLE_TYPES).forEach((type) => {
            if (result[type]) {
              const updatedData = result[type].map((item) => {
                const translatedText = translatedTexts[textIndex];
                textIndex++;

                return {
                  ...item,
                  text: translatedText || item.text,
                };
              });

              translatedResult[type] = updatedData;
            }
          });

          setcodeTableData(translatedResult);
        })
        .catch((error) => {
          console.error('Translation failed:', error);
          setcodeTableData(result);
        });

    });
  }, []);
 
  const renderMenuItemChildren = (option: any) => {
    const typeOfProjects = codeTableData[CODETABLE_TYPES.PROJECT_TYPES] || [];
  
    const projectTypeLabel = typeOfProjects.find(
      (projectType) => projectType.value === option.parentId
    )?.text;
  
    return (
      <Row>
        <Col md={12}>{option.text}</Col>
        {option.parentId && projectTypeLabel && (
          <Col md={12}  className="text-left small">Type of project: {projectTypeLabel}</Col>
        )}
      </Row>
    );
  };


  const popoverKeyword = (
    <Popover id="popover-keyword">
      <Popover.Body>
        Keyword selection from program content directly determines the Funding Programs linked to it.
        You may also opt not to type in any keyword and simply select a Type of Project and a Project Category independently.
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    const projectId = project.id
    if (!codeTableData || !codeTableData[CODETABLE_TYPES.PROJECT_CATEGORY] || ((projectId === 'new' || typeof projectId === 'undefined'))) {
      // console.log('new project returning', {projectId})
      return;
    };
    const fullLookupData = [...codeTableData[CODETABLE_TYPES.PROJECT_CATEGORY]];
    const filteredCtegoryData = fullLookupData?.filter((c) => {
      if (c.parentId === +project.typeOfProjectId) {
        c.label = c.text;
        return c;
      }
      return false;
    });
    setProjectCategoryList(filteredCtegoryData);

      if (combinedKeywordOptions && project.keywordIds) {
      const keywordIds: number[] = project.keywordIds.split(',').map(keywordId => +keywordId);
        const selectedKeywordOptions = combinedKeywordOptions.filter(codeValue => {

        if (keywordIds.includes(codeValue.value)) {
          return codeValue;
        }
        return false;
      })
      setMultiSelectKeyword(selectedKeywordOptions)
    }
  }, [project.typeOfProjectId, codeTableData, project.keywordIds, project.id]);

  const navigate = useNavigate();

  const combinedKeywordOptions = [
    ...(codeTableData[CODETABLE_TYPES.KEYWORD] || []),
    ...(codeTableData[CODETABLE_TYPES.PROJECT_CATEGORY] || []),
    ...(codeTableData[CODETABLE_TYPES.PROJECT_TYPES] || []),
    ...(codeTableData[CODETABLE_TYPES.FUNDING_TYPE] ||[] )
  ];
  const tenantId = process.env.REACT_APP_TENANT;


  return (


    <div className="project-edit-container layout-container-content d-flex flex-fill flex-column">
      <div className="d-flex flex-fill project-container">
        <main role="main" className="layout-main flex-fill">
          <Container>
            <div className="main">
              <div className="title-content">
                <ProjectsTilte>
                  Project Eligibility Questionnaire
                </ProjectsTilte>



                <span className="new-subtitle">Complete this short questionnaire to receive a customized list of federal funding
                  programs for which your project might be eligible. <b>All fields are optional. </b>
                  Leaving a field empty will include all categories under that field in your search,
                  while adding a response to a field will narrow your search to more relevant funding
                  options. Enter multiple keywords to broaden your search.  If you have a specific
                  type of project in mind, we suggest starting with a ‘keyword only’ search and then
                  narrowing down with other answers.</span>
              </div>
              <div >
                <span className="req-field">Please refer to <LinkColor href="https://hraadvisors-my.sharepoint.com/:w:/p/imontojo/ESnly8HfNi9BiOw103gDjcEB59NpYNky6wne7Ng0m4pJ7Q" target='_blank' rel="noreferrer">this</LinkColor> document for definitions of terms used in the questionnaire below.</span>
              </div>
              <br />
              <Form noValidate validated={validated} onSubmit={onSubmit}>
                <Row className="justify-content-md-center layout-main project-field-row">
                  <Col>
                  {user && (
  <Form.Group className="error-text">
  <Form.Control
    type="text"
    placeholder="Add a name to your project"
    id="title"
    name="title"
    value={project.title}
    onChange={(e: any) =>
      projectContext.handleProjectChange(
        e?.target?.name,
        e?.target?.value
      )
    }
  />
</Form.Group>
                   )}  

                  </Col>
                </Row>
                <Row className="justify-content-md-center project-field-row">
                  <Col>
                    <Typeahead
                      id="basic-typeahead-multiple"
                      multiple
                      placeholder="Keyword"
                      labelKey="text"
                      minLength={150}
                      onChange={(selected) => handleMultiSelect(selected, 'keywordIds', setMultiSelectKeyword)}
                      onBlur={(event: ChangeEvent<HTMLInputElement>) => handleOnBlur(event)}
                      options={combinedKeywordOptions}
                      renderMenuItemChildren={renderMenuItemChildren}
                      selected={multiSelectKeyword}
                    />
                  </Col>
                </Row>
                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popoverKeyword}>
                  <img className="keyword-tooltip" data-tip data-for="registerTip" src={img4} />
                </OverlayTrigger>
                <Row className="justify-content-md-center project-field-row">
                  <Col>
                    <SelectInput
                      inputTitle={"Type of Project "}
                      name="typeOfProjectId"
                      selectOptions={
                        codeTableData[CODETABLE_TYPES.PROJECT_TYPES] || []
                      }
                      value={project.typeOfProjectId}
                      propsValue={"value"}
                      propsLabel={"text"}
                      key={"id"}
                      onChange={(e: any) =>
                        handleTypeOfProjectChange(
                          e?.target?.value
                        )
                      }
                    />
                  </Col>
                  <Col>

                    <SelectInput
                      inputTitle={"Project Category "}
                      name="projectCategoryId"
                      selectOptions={projectCategoryList || []}
                      value={project.projectCategoryId}
                      propsValue={"value"}
                      propsLabel={"text"}
                      key={"id"}
                      onChange={(e: any) =>
                        projectContext.handleProjectChange(
                          e?.target?.name,
                          e?.target?.value
                        )
                      }
                    />
                  </Col>
                </Row>
                <Form.Group>
                  <Row className="justify-content-md-center newprojects-projectlocation-implementingentity-mH-ali project-field-row">
                    <Col>
                      <SelectInput
                        inputTitle={"Project Location "}
                        name="locationId"
                        selectOptions={
                          codeTableData[CODETABLE_TYPES.PROJECT_LOCATION] || []
                        }
                        value={project.locationId}
                        propsValue={"value"}
                        propsLabel={"text"}
                        key={"id"}
                        onChange={(e: any) =>
                          projectContext.handleProjectChange(
                            e?.target?.name,
                            e?.target?.value
                          )
                        }
                      />
                    </Col>
                    <Col>

                      <SelectInput
                        inputTitle={"Implementing Entity "}
                        name="implementingEntityId"
                        selectOptions={
                          codeTableData[CODETABLE_TYPES.IMPLEMENTING_ENTITY] ||
                          []
                        }
                        value={project.implementingEntityId}
                        propsValue={"value"}
                        propsLabel={"text"}
                        key={"id"}
                        onChange={(e: any) =>
                          projectContext.handleProjectChange(
                            e?.target?.name,
                            e?.target?.value
                          )
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center project-field-row">
                    <Col>
                      <SelectInput
                        inputTitle={"Approximate Project Cost"}
                        name="projectCostId"
                        selectOptions={
                          codeTableData[CODETABLE_TYPES.PROJECT_COST] || []
                        }
                        value={project.projectCostId}
                        propsValue={"value"}
                        propsLabel={"text"}
                        key={"id"}
                        onChange={(e: any) =>
                          projectContext.handleProjectChange(
                            e?.target?.name,
                            e?.target?.value
                          )
                        }
                      />
                    </Col>
                    <Col>

                      <SelectInput
                        inputTitle={"Project Activity "}
                        name="eligibleActivityId"
                        selectOptions={codeTableData[CODETABLE_TYPES.PROJECT_ACTIVITY] || []}
                        value={project.eligibleActivityId}
                        propsValue={"value"}
                        propsLabel={"text"}
                        key={"id"}
                        onChange={(e: any) =>
                          projectContext.handleProjectChange(
                            e?.target?.name,
                            e?.target?.value
                          )
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-start project-field-row">
                    <Col className="col-6">
                      <SelectInput
                        inputTitle={"Preparation Stage"}
                        name="preparationStageId"
                        selectOptions={
                          codeTableData[CODETABLE_TYPES.PROJECT_STAGE] || []
                        }
                        value={project.preparationStageId}
                        propsValue={"value"}
                        propsLabel={"text"}
                        key={"id"}
                        onChange={(e: any) =>
                          projectContext.handleProjectChange(
                            e?.target?.name,
                            e?.target?.value
                          )
                        }
                      />
                    </Col>
                    <Col>

                      <SelectInput
                        inputTitle={"Fund Type"}
                        name="fundingTypeId"
                        selectOptions={codeTableData[CODETABLE_TYPES.FUNDING_TYPE] || []}
                        value={project.fundingTypeId}
                        propsValue={"value"}
                        propsLabel={"text"}
                        key={"id"}
                        onChange={(e: any) =>
                          projectContext.handleProjectChange(
                            e?.target?.name,
                            e?.target?.value
                          )
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {tenantId === "2" && (
                        <SelectInput
                          inputTitle={"Program Status"}
                          name="programStatusId"
                          selectOptions={codeTableData[CODETABLE_TYPES.PROGRAM_STATUS] || []}
                          value={project.programStatusId}
                          propsValue={"value"}
                          propsLabel={"text"}
                          key={"id"}
                          onChange={(e: any) =>
                            projectContext.handleProjectChange(e?.target?.name, e?.target?.value)
                          }
                        />
                      )}
                    </Col>
                  </Row>

                  <br />
                </Form.Group>
                <ButtonContinue className="add-btn" type="submit">
                  {" "}
                  CONTINUE{" "}
                </ButtonContinue>
              </Form>
            </div>
          </Container>
        </main>
      </div>
    </div>
  );
}
