import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Card, Col, Container, Form, OverlayTrigger, Popover, Row, Spinner, Table } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import { IoIosArrowDown, IoIosArrowUp, IoMdArrowDropup, IoMdArrowDropdown, IoMdClose } from "react-icons/io";
import ButtonPrimary from "../../components/buttons/buttons";
import TitleCard from "../../components/title-card/titlecard";
import { BaseAPI } from "../../providers/base-api";
import { useAuth } from "../../hooks/useAuth";
import "./eligibleprogram.css";
import { formatCurrency } from "../../utils/common-utils";
import img4 from "../../assets/images/info-icon.png";
import { sortDataProgram } from "../../utils/common-utils";
import SignupOrLogin from "../../components/Modal/SignupOrLogin";
import dayjs from "dayjs";
import { AiOutlineFile, AiOutlineSelect } from "react-icons/ai";
import { useLocation } from 'react-router-dom';
import { BsClock } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import { CSVLink } from 'react-csv';
import { ButtonLearnmore, ButtonSignup, DocumentLinkColor, EligibleHead, LinkIcon, ProgramHead, ThemeContainer } from "../../components/styles/ThemeSwitching.styled";
import { IoDownloadOutline, IoFilterSharp } from "react-icons/io5";

export default function EligibleProgram() {
  useEffect(() => { });
  const [isSignUp, setIsSignUp] = useState(false);
  const { user } =
    useAuth();
  const hasSimilarPrograms = true || user?.sp;
  const [features, setFeatures] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const [fundingProgram, setFundingProgram] = useState([]);
  const [fundingEligibleProgram, setFundingEligibleProgram] = useState([]);
  const [modalType, setModalType] = useState("");
  const [isSorting, setIsSorting] = useState(true);
  const [isSortingData, setIsSortingData] = useState(true)
  const [inProgress, setInProgress] = useState({ eligible: false, all: false });
  const currentPath = useLocation();
  const isFundingProgramId = currentPath.pathname.split('/')?.[2];
  const [expandedIndex, setExpandedIndex] = useState(null);

  const processFundingProgram = (programTablePrefix, fundingProgram) => {
    let programStatus = fundingProgram.programStatus || fundingProgram.nofoLabel || fundingProgram.nofolabel
    fundingProgram.dueDate = fundingProgram.dueDate || fundingProgram.duedate
  
    if (["Expired Program", "Expired"].includes(programStatus)) {
      programStatus = 'Expired Program'
    } else if (["Deadline approaching", "Due"].includes(programStatus) || (!programStatus && fundingProgram.dueDate)) {
      programStatus = 'Deadline approaching'
    }
    if (!["Expired Program", "Deadline approaching", "Next Milestone to be Announced"].includes(programStatus) && fundingProgram.dueDate) {
      programStatus = 'Deadline approaching'
    }

    const dueDate = new Date(fundingProgram.dueDate);
    const currentDate = new Date();   
    if (currentDate > dueDate && !["Next Milestone to be Announced"].includes(programStatus)) {      
      programStatus = 'Expired Program';
    }
    fundingProgram.programStatus = programStatus
    fundingProgram.id = fundingProgram.id || fundingProgram.fundingProgramId
    fundingProgram.rowKey = `${programTablePrefix}-${fundingProgram.id}`
    if (fundingProgram.dueDate && programStatus === 'Deadline approaching') {
      const [day, month, year] = fundingProgram.dueDate.split('/').map(Number)
      const dateObject = new Date(year, month - 1, day);
      if (isNaN(dateObject.getDate())) {
        fundingProgram.formattedDate = null

      } else {
        const monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const formattedDate = `${monthNames[dateObject.getMonth()]} ${dateObject.getDate()} ${dateObject.getFullYear()}`;
        fundingProgram.formattedDate = formattedDate

      }
      fundingProgram.programStatusDisplay = `Due: ${new Date(fundingProgram.dueDate).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}`
    }
    if (!fundingProgram.programStatus) {
      fundingProgram.programStatusDisplay = undefined
    } else {
      fundingProgram.programStatusDisplay = fundingProgram.programStatusDisplay || fundingProgram.programStatus
    }
    if (!dayjs(fundingProgram.dueDate).isValid()) {
      fundingProgram.dueDate = null;
    }
    return fundingProgram
  }



  const getAllFundingProgram = async () => {
    try {
      setInProgress((prev) => { return { ...prev, all: true } })
      await BaseAPI.getAllFundingProgram(async (result: any) => {
        if (result.status === 200) {
          let final = result.data.map(data => {
            const processedRecord = processFundingProgram('ap', data);
            return processedRecord;
          })
          setFundingProgram(final);


        } else {
          console.log(result.message);
        }
        setInProgress((prev) => { return { ...prev, all: false } })

      });
    } catch (e) {
      console.log(e);
      setInProgress((prev) => { return { ...prev, all: false } })

    }
  };



  const getAllFundingEligibleProgram = async () => {
    try {
      setInProgress((prev) => { return { ...prev, eligible: true } })
      await BaseAPI.getAllFundingEligibleProgram((result: any) => {
        if (result.status === 200) {
          let final = result.data.map(data => {
            return processFundingProgram('ep', data);
          })
          setFundingEligibleProgram(final);
        } else {
          console.log(result.message);
        }
        setInProgress((prev) => { return { ...prev, eligible: false } })
      });
    } catch (e) {
      console.log(e);
      setInProgress((prev) => { return { ...prev, eligible: false } })
    }
  };

  const sortTableData = (order: boolean = false) => {
    setIsSorting(order)
    let data = sortDataProgram(fundingProgram, 'fundingProgramName', order ? 'asc' : 'desc');
    setFundingProgram(data);
  }
  const sortTableDataPrograms = (order: boolean = false) => {
    setIsSortingData(order)
    let data = sortDataProgram(fundingEligibleProgram, 'fundingProgramName', order ? 'asc' : 'desc');
    setFundingEligibleProgram(data);
  }

  useLayoutEffect(() => {
    const fundingProgramId = currentPath.pathname.split('/')?.[2];

    if (fundingProgramId && fundingProgram.length > 0) {
      const fundingProgramKey = `ep.${fundingProgramId}`
      const getFundingProgramElement = document.getElementById(fundingProgramKey);
      if (getFundingProgramElement) {
        getFundingProgramElement.scrollIntoView({ behavior: 'smooth', block: 'center', });
        // const ariaExpandedValue = true;
        getFundingProgramElement.setAttribute('aria-expanded', 'true');
        // const newClassName = 'expanded';
        getFundingProgramElement.classList.add('expanded');
        const FundingProgramExpanded = document.getElementById(`re.${fundingProgramId}`);
        const showClassName = 'show';
        FundingProgramExpanded.classList.add(showClassName);
      }
    }
  }, [fundingProgram, currentPath.pathname]);


  useEffect(() => {
    if (user) {
      setIsSignUp(false);
      getAllFundingEligibleProgram();
    } else {
      setIsSignUp(true);
    }
    getAllFundingProgram();
  }, [user]);
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <strong>Disclaimer: </strong>Based on publicly available information from the federal government, your project could meet the criteria for eligibility for this funding program. HR&A cannot guarantee eligibility or funding for your project.
      </Popover.Body>
    </Popover>
  );
  const [expandedId, setExpandedId] = useState(null);
  const [isVisible, setisVisible] = useState(false)
  const cardContainerRef = useRef(null);
  const toggleAccordion = (id, result) => {   
    result.visible = true;
    setisVisible(true)
    if (expandedId === id) {
      setExpandedId(null);
    } else {
      setExpandedId(id);
    }
  };
  const isIconRotated = (id) => expandedId === id;

  function getStatusClassName(status) {
    switch (status) {
      case 'Due':
      case "Deadline approaching":
        return 'status-due';
      case 'Expired':
      case 'Expired Program':
        return 'status-expired';
      case 'TBA':
      case 'Next Milestone to be Announced':
        return 'status-tba';
      default:
        return '';
    }
  }


  const scrollToId = (id, parentId) => {
    const parentElement = document.getElementById(parentId);
    if (parentElement) {
      parentElement.click();
    }
    setTimeout(() => {
      const targetElement = document.getElementById(id);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
        targetElement.click();
      }
    }, 400)
  };
  const getFeatures = async () => {
    try {
      await BaseAPI.getFeatures((result: any) => {
        if (result.status === 200) {
          setFeatures(result.data.f);

        } else {
          console.log(result.message);
        }

      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getFeatures();
  }, []);
  const tenantId = process.env.REACT_APP_TENANT;
  const [selectedStatus, setSelectedStatus] = useState('');

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const filteredPrograms = selectedStatus
    ? fundingProgram.filter(program => program.programStatus === selectedStatus)
    : fundingProgram;

  const scrollToSimilarPrograms = (isSimilarProgramId, fundingProgramActiveId) => {
    const similarProgramsElement = document.getElementById(`card_${isSimilarProgramId}`);
    const similarProgramIdx = fundingProgram.findIndex((program)=> program.id === isSimilarProgramId);
    if (similarProgramsElement && similarProgramIdx >= 0) {
      
      setExpandedIndex(similarProgramIdx)
      const similarProgramsActiveElement = document.getElementById(`ap-${isSimilarProgramId}`);
      similarProgramsActiveElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', });
    
    }
  }; 
  const csvData = fundingEligibleProgram.map(item => ({
    PROGRAMNAME : item.fundingProgramName,
    TYPEOFPROJECT:item.typeOfProjectName,
    PROJECTCATEGORY:item.projectCategoryName,
    AMOUNT:item.amount,
    PROJECTFUNDINGTYPE:item.ProjectFundingType,
    DUEDATE:item.dueDate,
    ELIGIBLERECIPIENTS:item.eligibleRecipients,
    FEDERALSHARE:item.federalShare,
    LINK:item.link,
    NOFOLABEL:item.nofolabel,
    PROGRAMSTATUS:item.programStatus,
    PURPOSE:item.purpose,
    ROWKEY:item.rowKey,
    STATESHARE:item.stateShare,
    URL:item.url


   }))
 
  const handleRowClicked = (index) =>{
    if (expandedIndex === index) {
      setExpandedIndex(null)
    } else {
      setExpandedIndex(index)
    }
  }

  return (
    <>

      <div className=" d-flex flex-fill flex-column">
        <main role="main" className="layout-main flex-fill eligible">
          <Container>
            <div className="main">
              <div className="eligible-programs-heading">
                {tenantId === "1" || (tenantId === "2" && !user) ? (
                  <TitleCard
                    title="Eligible Funding Opportunities"
                    description="Browse federal funding below or sign up to learn more about your specific project's eligibility and readiness to access funding."
                  />
                ) : (
                  <TitleCard
                    title={user ? "Competitive Funding Opportunities Curated for Puerto Rico" : "Eligible Funding Opportunities"}
                    description="Browse federal funding below or sign up to learn more about your specific project's eligibility and readiness to access funding."
                  />
                )}

              </div>

              {isSignUp ? (
                <Row className="eligible-programs-btnsign">
                  <Col>
                    <ButtonSignup
                      className="eligible-program-signup"
                      onClick={(event) => setModalType("login")}
                    >
                      SIGN UP &nbsp;
                      <ArrowRight className="arrow" size={28} />
                    </ButtonSignup>
                  </Col>
                </Row>
              ) : (
                <Row className="justify-content-md-center layout-main funding-eligibility-container-tit-tbl-gap">
                  <Col>
                    {inProgress.eligible ? (
                      <Row className="funding-eligibility-container-tbl-design">
                        <Col className="title-body-main">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </Col>
                      </Row>
                    ) : (!fundingEligibleProgram || fundingEligibleProgram.length === 0) ? (
                      <Row className="funding-eligibility-container-tbl-design">
                        <Col className="title-body-main">
                          <p className="title-body eligible-programs-description">
                            There are no matching eligible programs
                          </p>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="eligible-program-container-tbl-design">
                      <div className="row mb-2">                         
                          <div className="d-flex justify-content-end align-item-center btn-alignment ">
                                <CSVLink data={csvData} filename={'competitive_funding.csv'} className="csv-link" >
                                        <IoDownloadOutline/>   Export to CSV
                                 </CSVLink>
                            </div>
                      </div>
                        <Table className="table table-heading" id="eligible-programs-table">
                          <thead className={`eligibility-program-table-head-style-tenant${Number(process.env.REACT_APP_TENANT) === 1 ? "1" : "2"}`}>
                            <tr>
                              <th
                                scope="col"
                                className="eligible-program-programname"
                                style={{
                                  paddingLeft: "30px",
                                  height: "25px",
                                }}
                              >
                                <span>Program Name{isSorting ? <IoMdArrowDropup className="sort-data" onClick={() => sortTableData(false)} /> : <IoMdArrowDropdown className="sort-data" onClick={() => sortTableData(true)} />} </span>
                              </th>
                              <th
                                scope="col"
                                className="eligible-program-typeofproject"
                              >
                                <span>Type of Project{isSorting ? <IoMdArrowDropup className="sort-data" onClick={() => sortTableData(false)} /> : <IoMdArrowDropdown className="sort-data" onClick={() => sortTableData(true)} />}</span>
                              </th>
                              <th
                                scope="col"
                                className="eligible-program-projectcategory"
                              >
                                <span> Project Category{isSorting ? <IoMdArrowDropup className="sort-data" onClick={() => sortTableData(false)} /> : <IoMdArrowDropdown className="sort-data" onClick={() => sortTableData(true)} />}</span>
                              </th>
                              <th scope="col" className="eligible-program-amount">
                                <span>Amount{isSorting ? <IoMdArrowDropup className="sort-data" onClick={() => sortTableData(false)} /> : <IoMdArrowDropdown className="sort-data" onClick={() => sortTableData(true)} />}</span>
                              </th>
                              <th scope="col" className="eligible-program-amount">
                                <span> Fund Type{isSorting ? <IoMdArrowDropup className="sort-data" onClick={() => sortTableData(false)} /> : <IoMdArrowDropdown className="sort-data" onClick={() => sortTableData(true)} />}</span>
                              </th>
                              <th scope="col"></th>
                            </tr>
                          </thead>



                          {fundingEligibleProgram.map(
                            (result: any, index1: number) => (
                              <tbody key={`epb${result.rowKey}`} >
                                <tr
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#re-${result.rowKey}`}

                                  className={`eligible-program-tb-body ${expandedId === result.rowKey ? 'expanded' : ''}`}
                                  id={result.rowKey}
                                  onClick={() => toggleAccordion(result.rowKey, result)}

                                >
                                  <td className="ep-name" style={{ paddingLeft: "30px" }}>
                                    {

                                      result?.programStatus ? (
                                        <>
                                          <p className={`badge-nofo-label ${getStatusClassName(result?.programStatus)}`} >
                                            {result.programStatus === "Expired Program" ? <GrClose className="close-icon-size" /> : <BsClock className="clock-icon-size" />} {result.programStatusDisplay}
                                          </p>
                                        </>
                                      ) : <></>
                                    }

                                    {result?.fundingProgramName}
                                  </td>


                                  <td className="ep-body-top ">
                                    {result?.typeOfProjectName}
                                  </td>
                                  <td className="ep-body-pc">
                                    {result?.projectCategoryName}
                                  </td>
                                  <td className="ep-body-amt">
                                    {formatCurrency(result?.amount)}
                                  </td>
                                  <td className="ep-body-pc">
                                    <div className="badge">
                                      {result?.ProjectFundingType}
                                    </div>
                                  </td>

                                  <td>
                                    <IoIosArrowDown
                                      className={`eligible-program-drp-down arrow-icon ${isIconRotated(result.rowKey) ? 'rotate-icon' : ''}`}
                                      onClick={() => toggleAccordion(result.rowKey, result)}
                                    />
                                  </td>

                                </tr>
                                <tr
                                  className={`ep-container-accordian accordion-collapse data-container ${expandedId !== result.rowKey ? 'fade-out' : 'fade-in'} ${result.visible ? 'transition-visible' : 'eligible-transition'}`}
                                  id={`re-${result.rowKey}`}
                                  data-bs-parent="#eligible-programs-table"
                                >
                                  <td colSpan={12} className="div-hover" style={{ borderColor: "white" }}>

                                    <Row className="recipients">
                                      <Col>
                                        {result?.eligibleRecipients && (
                                          <>
                                            <h4 className="eligible-program-recipients">
                                              Eligible Recipients:
                                            </h4>
                                            <p className="eligible-program-recipients-drop-down">
                                              {result?.eligibleRecipients}
                                            </p>
                                          </>
                                        )}
                                      </Col>
                                    </Row>

                                    <Row className="recipients">
                                      <div>
                                        <h4 className="eligibility-program-recipients">Cost Share</h4>
                                        <div className="eligible-program-cost-share">
                                          <div className="eligible-program-cost-share-text  ">
                                            <h3 className="notranslate" translate="no">{result?.federalShare || '0%'}</h3>
                                            <span className="funding-eligibility-fedralShare">Federal Share</span>
                                          </div>
                                          <div  >
                                            <h3 className="notranslate" translate="no" >  {result?.stateShare || '0%'} </h3>
                                            <span className="funding-eligibility-fedralShare">State Share</span>
                                          </div>
                                        </div>
                                      </div>
                                    </Row>
                                    <Row className="recipients">
                                      <Col>
                                        <h4 className="eligible-program-purpose">
                                          Purpose:
                                        </h4>
                                        <p className="eligible-program-purpose-drop-body">
                                          {result?.purpose}
                                        </p>
                                      </Col>
                                    </Row>
                                    <Row className="recipients button-padding">
                                      <Col>
                                        {
                                          result?.link ?
                                            <ButtonLearnmore className="eligibility-container-btn-learn"
                                              href={result?.link}
                                              target='_blank'
                                              rel="noreferrer"

                                            >
                                              LEARN MORE
                                            </ButtonLearnmore> : null
                                        }
                                      </Col>
                                    </Row>
                                    {result.fundingProgramLink && result.fundingProgramLink.length > 0 && (
                                      <Row className="recipients">

                                        <>
                                          {result.fundingProgramLink
                                            .filter(link => ['nofa', 'nofo'].includes(link.type) && link.label)
                                            .map((documentLink, index) => (
                                              <Row>

                                                <Col>
                                                  {result.fundingProgramLink.some(link => ['nofa', 'nofo'].includes(link.type) && link.label) && (
                                                    <h4 className="eligible-program-documents">Documents:</h4>
                                                  )}

                                                  <div key={index}>

                                                    <span key={index} className="eligible-program-document-body">
                                                      <DocumentLinkColor href={documentLink.hyperlink} target="_blank" rel="noopener noreferrer">
                                                        <LinkIcon className="icon-style">
                                                          <AiOutlineFile className="document-icon-size" />
                                                        </LinkIcon> {documentLink.label}
                                                      </DocumentLinkColor>
                                                    </span>
                                                    {documentLink.type === 'nofa' ? (
                                                      <LinkIcon className="text-style">NOFA</LinkIcon>
                                                    ) : (
                                                      <LinkIcon className="text-style">NOFO</LinkIcon>
                                                    )}
                                                  </div>

                                                </Col>

                                              </Row>
                                            ))}
                                          {result.fundingProgramLink
                                            .filter(link => link.type === 'link' && link.label)
                                            .map((additionalLink, index) => (
                                              <Row>

                                                <Col>
                                                  {result.fundingProgramLink.some(link => link.type === 'link' && link.label) && (
                                                    <h4 className="eligible-program-links">Additional Links:</h4>
                                                  )}

                                                  <span key={index} className="eligible-program-purpose-drop-body">
                                                    <DocumentLinkColor className="icon-align" href={additionalLink.hyperlink} target="_blank" rel="noopener noreferrer">
                                                      <LinkIcon className="link-icon-style">
                                                        <AiOutlineSelect className="link-icon-size" />
                                                      </LinkIcon> {additionalLink.label}
                                                    </DocumentLinkColor>
                                                  </span>

                                                </Col>

                                              </Row>
                                            ))}
                                        </>

                                      </Row>
                                    )}
                                  </td>

                                </tr>
                              </tbody>
                            )
                          )}
                        </Table>

                      </Row>
                    )}
                  </Col>
                </Row>
              )}
              <Row className="eligible-programs-subheading">
                {tenantId === "1" && (
                  <TitleCard
                    title="All Federal Funding Opportunities"
                    description="This comprehensive list is regularly updated to reflect all federal infrastructure funding programs."
                  />
                )}
                {tenantId === "2" && !user && (
                  <TitleCard
                    title="Competitive Funding Opportunities Curated for Puerto Rico"
                    description="This comprehensive list is regularly updated to reflect all federal infrastructure funding programs."
                  />
                )}
              </Row>

              {(tenantId === "1" || (tenantId === "2" && !user)) && (
                <>


                  {tenantId === "2" && (
                    <>
                      <div className="position-relative">
                        <IoFilterSharp className="filter-icon" />
                        <Form.Select
                          id="programStatusDropdown"
                          value={selectedStatus}
                          onChange={handleStatusChange}
                          className="programstatus-dropdown dropdown-select"
                        >
                          <option value="">All</option>
                          {fundingProgram
                            .map(program => program.programStatus)
                            .filter(status => status)
                            .filter((value, index, self) => self.indexOf(value) === index)
                            .map((status, index) => (
                              <option key={index} value={status}>{status}</option>
                            ))}
                        </Form.Select>
                      </div>
                    </>
                  )}
                  <Row className="eligible-program-container-tbl-design">
                    <Table className="table-heading" id="all-programs-table">
                      <thead className={`eligibility-program-table-head-style-tenant${Number(process.env.REACT_APP_TENANT) === 1 ? "1" : "2"}`}>
                        <tr>
                          <th
                            scope="col"
                            className="eligible-program-programname"
                            style={{
                              paddingLeft: "30px",
                              height: "25px",
                            }}
                          >
                            <span>Program Name{isSorting ? <IoMdArrowDropup className="sort-data" onClick={() => sortTableData(false)} /> : <IoMdArrowDropdown className="sort-data" onClick={() => sortTableData(true)} />} </span>
                          </th>
                          <th
                            scope="col"
                            className="eligible-program-typeofproject"
                          >
                            <span> Type of Project{isSorting ? <IoMdArrowDropup className="sort-data" onClick={() => sortTableData(false)} /> : <IoMdArrowDropdown className="sort-data" onClick={() => sortTableData(true)} />}</span>
                          </th>
                          <th
                            scope="col"
                            className="eligible-program-projectcategory"
                          >
                            <span>Project Category{isSorting ? <IoMdArrowDropup className="sort-data" onClick={() => sortTableData(false)} /> : <IoMdArrowDropdown className="sort-data" onClick={() => sortTableData(true)} />}</span>
                          </th>
                          <th scope="col" className="eligible-program-amount">
                            <span>Amount{isSorting ? <IoMdArrowDropup className="sort-data" onClick={() => sortTableData(false)} /> : <IoMdArrowDropdown className="sort-data" onClick={() => sortTableData(true)} />}</span>
                          </th>
                          <th scope="col" className="eligible-program-amount">
                            <span> Fund Type{isSorting ? <IoMdArrowDropup className="sort-data" onClick={() => sortTableData(false)} /> : <IoMdArrowDropdown className="sort-data" onClick={() => sortTableData(true)} />}</span>
                          </th>
                          <th scope="col"></th>
                        </tr>
                      </thead>

                      {inProgress.all ? (
                        <tbody key='all-programs-loading'>
                          <tr className="eligible-program-tb-body">
                            <td className="ep-name" colSpan={5}>
                              <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading... </span>
                              </Spinner>
                            </td>
                          </tr>
                        </tbody>
                      ) :
                        (filteredPrograms.map((result: any, index2: number) => (
                          <tbody key={result.rowKey}>
                            <tr
                              data-bs-toggle="collapse"
                              data-bs-target={`#re-${result.rowKey}`}
                              className={`eligible-program-tb-body ${expandedId === result.rowKey ? 'expanded' : ''} `}
                              id={result.rowKey}
                              onClick={() => handleRowClicked(index2)}
                            >
                              <td className="ep-name" style={{ paddingLeft: "30px" }}>
                                {result?.programStatus ? (
                                  <>
                                    <p className={`badge-nofo-label ${getStatusClassName(result?.programStatus)}`}>
                                      {["Expired Program", "Expired"].includes(result.programStatus) ? <GrClose className="close-icon-size" /> : <BsClock className="clock-icon-size" />} {result.programStatusDisplay}
                                    </p>
                                  </>
                                ) : <></>}


                                {result?.fundingProgramName}
                              </td>

                              <td className="ep-body-top ">
                                {result?.typeOfProjectName}
                              </td>
                              <td className="ep-body-pc">
                                {result?.projectCategoryName}
                              </td>
                              <td className="ep-body-amt">
                                {formatCurrency(result?.amount)}
                              </td>
                              <td className="ep-body-pc">
                                <div className="badge">
                                  {result?.ProjectFundingType}
                                </div>
                              </td>

                              <td>
                                <IoIosArrowDown
                                  className={`eligible-program-drp-down arrow-icon ${ expandedIndex === index2 ? 'rotate-icon' : ''}`}
                                  />
                              </td>
                            </tr>
                            {expandedIndex === index2 && 
                            <tr
                              // data-bs-toggle="collapse"
                              className={`ep-container-accordian accordion-collapse data-container ${expandedIndex !== index2 ? 'fade-out' : 'fade-in'}`}
                              id={`re-${result.rowKey}`}
                              data-bs-parent="#all-programs-table"                             
                            >
                              <td colSpan={6} className="div-hover" style={{ borderColor: "white" }}>
                                <Row className="recipients">
                                  <Col>
                                    <h4 className="eligible-program-recipients">
                                      Eligible Recipients:
                                    </h4>
                                    <p className="eligible-program-recipients-drop-down">
                                      {result?.eligibleRecipients}
                                    </p>

                                  </Col>
                                  <div>
                                    <h4 className="eligibility-program-recipients">Cost Share</h4>
                                    <div className="eligible-program-cost-share">
                                      <div className="eligible-program-cost-share-text  ">
                                        <h3 className="notranslate" translate="no">{result?.federalShare || '0%'}</h3>
                                        <span className="funding-eligibility-fedralShare ">Federal Share</span>
                                      </div>
                                      <div  >
                                        <h3 className="notranslate" translate="no">{result?.stateShare || '0%'}</h3>
                                        <span className="funding-eligibility-fedralShare  ">State Share</span>
                                      </div>
                                    </div>
                                  </div>



                                  {/* </Row> */}
                                </Row>
                                <Row className="recipients">
                                  <Col>
                                    <h4 className="eligible-program-purpose">
                                      Purpose:
                                    </h4>
                                    <p className="eligible-program-recipients-drop-down">
                                      {result?.purpose}
                                    </p>
                                  </Col>
                                </Row>
                                <Row className="recipients button-padding">
                                  <Col>
                                    {result?.link ?
                                      <ButtonLearnmore className="eligibility-container-btn-learn"
                                        href={result?.link}
                                        target='_blank'
                                        rel="noreferrer">
                                        LEARN MORE
                                      </ButtonLearnmore> : null}
                                  </Col>
                                </Row>
                                <Row className="recipients">
                                  {result.fundingProgramLink && result.fundingProgramLink.length > 0 && (
                                    <>
                                      <Row>
                                        <Col>
                                          {result.fundingProgramLink.some(link => ['nofa', 'nofo'].includes(link.type) && link.label) && (
                                            <h4 className="eligible-program-documents">Documents:</h4>
                                          )}

                                          {result.fundingProgramLink
                                            .filter(link => ['nofa', 'nofo'].includes(link.type) && link.label)
                                            .map((documentLink, index) => (
                                              <div key={index}>

                                                <span key={index} className="eligible-program-document-body">
                                                  <DocumentLinkColor href={documentLink.hyperlink} target="_blank" rel="noopener noreferrer">
                                                    <LinkIcon className="icon-style">
                                                      <AiOutlineFile className="document-icon-size" />
                                                    </LinkIcon>  {documentLink.label}

                                                  </DocumentLinkColor>
                                                </span>
                                                {documentLink.type === 'nofa' ? (
                                                  <LinkIcon className="text-style">NOFA</LinkIcon>
                                                ) : (
                                                  <LinkIcon className="text-style">NOFO</LinkIcon>
                                                )}
                                              </div>
                                            ))}

                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          {result.fundingProgramLink.some(link => ((link.type === 'link' || !link.type) && link.label)) && (
                                            <h4 className="eligible-program-links">Additional Links:</h4>
                                          )}
                                          {result.fundingProgramLink
                                            .filter(link => ((link.type === 'link' || !link.type) && link.label))
                                            .map((additionalLink, index) => (
                                              <span key={index} className="eligible-program-link-body">
                                                <DocumentLinkColor href={additionalLink.hyperlink} target="_blank" rel="noopener noreferrer">
                                                  <LinkIcon className="link-icon-style">
                                                    <AiOutlineSelect className="link-icon-size" />
                                                  </LinkIcon>  {additionalLink.label}
                                                </DocumentLinkColor>
                                              </span>
                                            ))}
                                        </Col>
                                      </Row>
                                    </>
                                  )}
                                </Row>

                                {(true || (features && features.length > 0 && features[1].enabled === 'true')) ? (
                                  <div className="recipients">
                                    {result?.relatedPrograms && Array.isArray(result.relatedPrograms) && result.relatedPrograms.length > 0 ? (
                                      <h4 className="eligible-program-similar">Similar Programs:</h4>
                                    ) : null}
                                    <div className="main-container">
                                      {result?.relatedPrograms && Array.isArray(result.relatedPrograms) ? (
                                        result.relatedPrograms.map((item, index) => (
                                          <div className="sub-container" key={index}>
                                            <Card className={`card-fund${expandedId === item.id ? 'expanded' : ''}`} id={`card_${item.id}`} onClick={() => scrollToSimilarPrograms(item.id, result.id)}>
                                              <Card.Body>
                                                <p className="eligible-program-purpose-drop-body">
                                                  {item.name}
                                                </p>
                                              </Card.Body>
                                            </Card>
                                          </div>
                                        ))

                                      ) : null}
                                    </div>
                                  </div>

                                ) : null}
                              </td>

                            </tr>
                      }
                          </tbody>
                        )))}

                    </Table>
                  </Row></>

              )}

            </div>
          </Container>
        </main>
      </div>
      <SignupOrLogin modalType={modalType} setModalType={setModalType}></SignupOrLogin>

    </>
  );
}
